<template>
  <div class="app">
    <img src="@/assets/logo.png" class="img" />
    <HelloWorldVue :msg="'Welcome to Your Vue.js App'" />
  </div>
</template>

<script>
import HelloWorldVue from "@/components/HelloWorld.vue";
export default {
  name: "app",
  components: { HelloWorldVue },
};
</script>

<style scoped>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin-bottom: 100px;
}
.img {
  margin-bottom: 100px;
}
</style>