<template>
  <div class="app">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
      background-color="#ECF5FF" text-color="#000" active-text-color="#409EFF">
      <el-menu-item index="1">首页</el-menu-item>
      <el-submenu index="2">
        <template slot="title">我的工作台</template>
        <el-menu-item index="2-1">搜索查找</el-menu-item>
        <el-menu-item index="2-2">职工信息</el-menu-item>
        <!-- <el-menu-item index="2-3">职工详情</el-menu-item> -->
      </el-submenu>
      <el-menu-item index="3" disabled class="path_bar">{{
        path
        }}</el-menu-item>
      <el-menu-item index="4"> 关于 </el-menu-item>
      <el-menu-item index="5"> 赣ICP备2024020920号-1 </el-menu-item>
    </el-menu>
    <div class="router_view"><router-view></router-view></div>
  </div>
</template>

<script>
import redirect from "./utils/Redirect";
export default {
  name: "app",
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      switch (key) {
        case "1":
          redirect(this, "/index/search");
          sessionStorage.setItem("index_key", "1");
          break;
        case "2-1":
          redirect(this, "/search");
          sessionStorage.setItem("index_key", "2-1");
          break;
        case "2-2":
          redirect(this, "/list");
          sessionStorage.setItem("index_key", "2-2");
          break;
        case "4":
          redirect(this, "/about");
          sessionStorage.setItem("index_key", "4");
          break;
        case "5":
          window.location.href = "https://beian.miit.gov.cn/";
          break;
      }
    },
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
  created() {
    this.activeIndex = sessionStorage.getItem("index_key");
  },
};
</script>

<style>
body {
  margin: 0px;
  overflow: hidden;
}

.router_view {
  width: calc(100vw);
  height: calc(100vh - 60px);
  overflow: auto;
}

.path_bar {
  width: 200px;
  overflow-x: auto;
  overflow-y: hidden;
  user-select: none;
  cursor: default !important;
}

.is-leaf {
  border-bottom: 2px solid #409eff !important;
}

.el-table__cell {
  text-align: center !important;
}

.search_list_box .el-table__header-wrapper {
  display: none;
}

.highlight {
  background-color: #f56c6c7f;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #409eff, #d9ecff);
  border-radius: 10px;
  border: 2px solid rgb(65, 65, 65);
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
</style>