<template>
  <div class="app">
    <div class="tag" @dblclick="showInp">{{ outTag }}</div>
    <input
      v-model="tag"
      ref="inp"
      @keydown="keyModify"
      @blur="blurModify"
      v-show="flag"
      type="text"
    />
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "app",
  props: {
    outTag: { type: String, default: "标签" },
    outItem: Object,
  },
  data() {
    return {
      tag: "",
      flag: false,
    };
  },
  methods: {
    showInp() {
      this.tag = this.outTag;
      this.flag = true;
      this.$nextTick(() => {
        this.$refs.inp.focus();
      });
    },
    keyModify(e) {
      if (e.keyCode === 13) this.sentEmit();
    },
    blurModify() {
      this.sentEmit();
    },
    sentEmit() {
      if (
        typeof this.tag === "undefined" ||
        this.tag === null ||
        this.tag.trim().length === 0
      ) {
        this.tag = "标签";
      }
      let obj = _.cloneDeep(this.outItem);
      obj.prof = this.tag;
      this.$emit("tagModify", obj);

      setTimeout(() => {
        this.flag = false;
      }, 500);
    },
  },
};
</script>

<style>
.tag {
  user-select: none;
  /* position: absolute; */
  width: 105px;
  border: 1px solid #409eff;
  text-align: center;
}

input {
  height: 21px;
  /* position: absolute; */
  width: 100px;
  text-align: center;
  border: 2px solid crimson;
}

input:focus {
  border: 2px solid crimson;
  outline: none; /* 去除聚焦时浏览器默认的轮廓线 */
}

.app {
  position: relative;
}
</style>