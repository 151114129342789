<template>
  <div id="app">
    <div class="search_list_box table_box">
      <el-table :data="tableData" style="width: 100%" class="table_body">
        <el-table-column prop="tno" width="80">
          <template v-slot="scope">
            <el-tag>
              <span v-html="highlightKeyword(scope.row.tno)"></span>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="tname" width="80">
          <template v-slot="scope">
            <span v-html="highlightKeyword(scope.row.tname)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="tsex" width="80">
          <template v-slot="scope">
            <span v-html="highlightKeyword(scope.row.tsex)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="tbirthday" width="250">
          <template v-slot="scope">
            <span v-html="highlightKeyword(scope.row.tbirthday)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="prof" width="120">
          <template v-slot="scope">
            <span v-html="highlightKeyword(scope.row.prof)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="depart">
          <template v-slot="scope">
            <span v-html="highlightKeyword(scope.row.depart)"></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "app",
  data() {
    return {
      tableData: [],
      keywords: [],
    };
  },
  methods: {
    highlightKeyword(text) {
      text = text + "";

      for (const i in this.keywords) {
        if (!text || !this.keywords[i]) continue;

        const regex = new RegExp(`(${this.keywords[i]})`, "gi");
        if (!regex.test(text)) continue;

        text = text.replace(regex, '<span class="highlight">$1</span>');
      }

      return text;
    },
    getByIds() {
      this.keywords = this.$route.params.keys.replace("keys=", "").split("+");

      this.tableData = [];
      let ids = this.$route.params.ids.replace("ids=", "").split("+");

      axios
        .post(`${process.env.VUE_APP_BASE_URL}/teachers/ids`, ids)
        .then((res) => {
          if (res.data.code !== 20041) {
            this.$message.error(res.data.msg);
            console.log(res.data);
            return;
          }

          this.tableData = res.data.data;
        })
        .catch((err) => {
          this.$message.error("请求失败：" + err.stack);
          console.log("Error v");
          console.log(err);
        });
    },
  },
  watch: {
    $route() {
      this.getByIds();
    },
  },
  created() {
    this.getByIds();
  },
};
</script>

<style scoped>
.box {
  height: 26px;
}
.table_box {
  width: 800px;
  margin: 10px auto;
}
</style>