import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/Index.vue";
import List from "@/views/List.vue";
import About from "@/views/About.vue";
import Search from "@/views/Search.vue";
import Detail from "@/views/Detail.vue";
import SearchList from "@/views/SearchList.vue";


Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        { path: '/', component: Index, name: 'index' },
        {
            path: '/index', component: Index, children: [
                { path: '/index/list', component: List },
                { path: '/index/about', component: About },
                {
                    path: 'search', component: Search, children: [
                        { path: 'search_list/:keys/:ids', component: SearchList },
                        { path: 'detail/:keys/:id', component: Detail },
                    ]
                },
            ]
        },
        { path: '/list', component: List, name: 'list' },
        { path: '/about', component: About, name: 'about' },
        { path: '/search_list', component: SearchList, name: 'search_list' },
        { path: '/detail/:id', component: Detail, name: 'detail' },
        {
            path: '/search', component: Search, children: [
                { path: 'search_list/:keys/:ids', component: SearchList },
                { path: 'detail/:keys/:id', component: Detail },
            ], name: 'search'
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.path === '/' || to.path === '/index') {
        next('/index/search')
    } else {
        next()
    }
})

export default router