<template>
  <el-card class="teacher-card" shadow="always">
    <el-descriptions title="详情信息" border>
      <el-descriptions-item label="编号">
        <el-tag><span v-html="highlightKeyword(teacher.tno)"></span></el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="姓名">
        <span v-html="highlightKeyword(teacher.tname)"></span>
      </el-descriptions-item>
      <el-descriptions-item label="性别">
        <span v-html="highlightKeyword(teacher.tsex)"></span>
      </el-descriptions-item>
      <el-descriptions-item label="生日">
        <span v-html="highlightKeyword(teacher.tbirthday)"></span>
      </el-descriptions-item>
      <el-descriptions-item label="职位">
        <span v-html="highlightKeyword(teacher.prof)"></span>
      </el-descriptions-item>
      <el-descriptions-item label="院系">
        <span v-html="highlightKeyword(teacher.depart)"></span>
      </el-descriptions-item>
    </el-descriptions>
  </el-card>
</template>

<script>
import axios from "axios";
export default {
  name: "app",
  data() {
    return {
      teacher: {},
      keywords: [],
    };
  },
  methods: {
    highlightKeyword(text) {
      text = text + "";

      for (const i in this.keywords) {
        if (!text || !this.keywords[i]) continue;

        const regex = new RegExp(`(${this.keywords[i]})`, "gi");
        if (!regex.test(text)) continue;

        text = text.replace(regex, '<span class="highlight">$1</span>');
      }

      return text;
    },
    getById() {
      this.keywords = this.$route.params.keys.replace("keys=", "").split("+");

      this.teacher = {};
      axios
        .get(
          `${process.env.VUE_APP_BASE_URL}/teachers/${this.$route.params.id}`
        )
        .then((res) => {
          if (res.data.code !== 20041) {
            this.$message.error(res.data.msg);
            console.log(res.data);
            return;
          }

          this.teacher = res.data.data;
        })
        .catch((err) => {
          this.$message.error("请求失败：" + err.stack);
          console.log("Error v");
          console.log(err);
        });
    },
  },
  watch: {
    $route() {
      this.getById();
    },
  },
  created() {
    this.getById();
  },
};
</script>

<style scoped>
.teacher-card {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
}
</style>
