<template>
  <div id="app">
    <div class="table_box">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="tno" label="编号" width="80"> </el-table-column>
        <el-table-column prop="tname" label="姓名" width="80">
        </el-table-column>
        <el-table-column prop="tsex" label="性别" width="80"> </el-table-column>
        <el-table-column prop="tbirthday" label="出生日期" width="250">
        </el-table-column>
        <el-table-column prop="prof" label="职位" width="120">
          <template v-slot="scope">
            <div class="box">
              <Tag
                @tagModify="tagModify"
                :outTag="scope.row.prof"
                :outItem="scope.row"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="depart" label="院系"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Tag from "@/components/Tag.vue";
export default {
  name: "App",
  components: {
    Tag,
  },
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    tagModify(item) {
      let id = item.tno;
      let thisProf = this.tableData.filter((thisItem) => thisItem.tno == id)[0]
        .prof;

      if (thisProf != item.prof) {
        axios
          .put(`${process.env.VUE_APP_BASE_URL}/teachers`, {
            tno: item.tno,
            tname: item.tname,
            tsex: item.tsex,
            tbirthday: item.tbirthday,
            prof: item.prof,
            depart: item.depart,
          })
          .then((res) => {
            console.log(res.data);
            this.getAll();
            setTimeout(() => {
              this.$message.success("修改成功");
            }, 500);
          })
          .catch((err) => {
            this.$message.error("请求失败：" + err.stack);
            console.log("Error v");
            console.log(err);
          });
      }
    },
    getAll() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/teachers`)
        .then((res) => {
          this.tableData = res.data.data;
        })
        .catch((err) => {
          this.$message.error("请求失败：" + err.stack);
          console.log("Error v");
          console.log(err);
        });
    },
  },
  created() {
    this.getAll();
  },
};
</script>

<style scoped>
.box {
  height: 26px;
  /* border: 1px solid red; */
}
.table_box {
  width: 700px;
  margin: 0 auto;
}
.el-table__cell {
  text-align: center !important;
}
</style>
